import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  MarkForDeleteEmployeeApplicationSettings,
  GetEmployeeApplicationSettings,
  GetUsersForEmployeeApplicationSettings,
  SetEmployeeApplicationSettings,
  GetEmployeeNotificationSettings,
  SetEmployeeNotificationSettings,
  ResetEmployeeNotificationSettings,
  ResetEmployeeApplicationSettings,
  GetEmployeeFaceToFaceReviewersListSetting,
  UpdateEmployeeFaceToFaceReviewersListSetting,
  DeleteEmployeeFaceToFaceReviewer,
  SetInterviewer,
  GetTypeSignatureStatus,
  SetTypeSignatureStatus,
  ChangeTypeSignatureStatus,
} from './settings.actions';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { SettingsStateModel } from './settings.models';
import { SettingsService } from '../../services/settings.service';
import { tap, catchError } from 'rxjs/operators';
import { ClearTrainingPlans, GetTrainingPlanAssignSetting, GetTrainingPlansForAssign, UpdateTrainingPlanAssignSetting } from './settings.actions';
import {
  EmployeeApplicationSettings,
  EmployeeFaceToFaceReviewersModel,
  EmployeeNotificationSettings,
  ErrorsTrainingPlan,
  TrainingPlanAssignSettingModel,
} from '../../../shared/models/settings.models';
import { IdTitleObject } from '../../../shared/models';
import { HttpErrorResponse } from '@angular/common/http';

const defaultState: SettingsStateModel = {
  trainingPlanAssignSettingData: null,
  trainingPlansForAssign: null,
  errorsTrainingPlan: null,
  emplAppSettings: {
    type: 'manual',
    users: [],
  },
  notificationsSettings: null,
  markForDelete: [],
  usersList: [],
  reviewersList: null,
  isLoading: false,
  isEnableTypeSignature: null,
};

@State({
  name: 'settings',
  defaults: defaultState,
})
@Injectable()
export class SettingsState {
  constructor(private settingsService: SettingsService) {}

  @Selector()
  static isEnableTypeSignature(state): boolean {
    return state.isEnableTypeSignature;
  }

  @Selector()
  static reviewersList(state): EmployeeFaceToFaceReviewersModel[] {
    return state.reviewersList;
  }

  @Selector()
  static isLoading(state): boolean {
    return state.isLoading;
  }

  @Selector()
  static employeeApplicationSettings(state): boolean {
    return state.emplAppSettings;
  }

  @Selector()
  static notificationSettings(state): boolean {
    return state.notificationsSettings;
  }

  @Selector()
  static users(state): boolean {
    return state.emplAppSettings.users;
  }

  @Selector()
  static trainingPlanAssignSettingData(state: SettingsStateModel): TrainingPlanAssignSettingModel {
    return state.trainingPlanAssignSettingData;
  }

  @Selector()
  static trainingPlansForAssign(state: SettingsStateModel): IdTitleObject[] {
    return state.trainingPlansForAssign;
  }

  @Selector()
  static errorsTrainingPlan(state: SettingsStateModel): ErrorsTrainingPlan {
    return state.errorsTrainingPlan;
  }

  @Action(GetEmployeeApplicationSettings)
  getEmployeeApplicationSettings(ctx: StateContext<SettingsStateModel>): Observable<EmployeeApplicationSettings> {
    return this.settingsService.getEmployeeApplicationSettings().pipe(
      tap(value => {
        ctx.patchState({
          emplAppSettings: value,
        });
      }),
    );
  }

  @Action(SetInterviewer)
  setInterviewer(ctx: StateContext<SettingsStateModel>, { interviewer }: SetInterviewer) {
    const currentList: EmployeeFaceToFaceReviewersModel[] = ctx.getState().reviewersList;
    const interviewerExists: boolean = currentList?.some((item: EmployeeFaceToFaceReviewersModel): boolean => item.uid === interviewer.uid);
    if (!interviewerExists) {
      ctx.patchState({
        reviewersList: [...(currentList || []), interviewer],
      });
    }
  }

  @Action(GetEmployeeFaceToFaceReviewersListSetting)
  getEmployeeFaceToFaceReviewersListSetting(ctx: StateContext<SettingsStateModel>): Observable<EmployeeFaceToFaceReviewersModel[]> {
    return this.settingsService.getEmployeeFaceToFaceReviewersListSetting().pipe(
      tap((reviewers: EmployeeFaceToFaceReviewersModel[]) => {
        ctx.patchState({
          reviewersList: reviewers,
        });
      }),
    );
  }

  @Action(UpdateEmployeeFaceToFaceReviewersListSetting)
  updateEmployeeFaceToFaceReviewersListSetting(
    ctx: StateContext<SettingsStateModel>,
    { users }: UpdateEmployeeFaceToFaceReviewersListSetting,
  ): Observable<EmployeeFaceToFaceReviewersModel[]> {
    return this.settingsService.updateEmployeeFaceToFaceReviewersListSetting(users).pipe(
      tap((reviewers: EmployeeFaceToFaceReviewersModel[]) => {
        ctx.patchState({
          reviewersList: reviewers,
        });
      }),
    );
  }

  @Action(DeleteEmployeeFaceToFaceReviewer)
  deleteEmployeeFaceToFaceReviewer(ctx: StateContext<SettingsStateModel>, { uid }: DeleteEmployeeFaceToFaceReviewer): void {
    ctx.patchState({
      reviewersList: ctx.getState()?.reviewersList.filter((reviewer: EmployeeFaceToFaceReviewersModel): boolean => reviewer.uid !== uid),
    });
  }

  @Action(GetUsersForEmployeeApplicationSettings)
  getUsersForEmployeeApplicationSettings(
    ctx: StateContext<SettingsStateModel>,
    { search }: GetUsersForEmployeeApplicationSettings,
  ): Observable<Array<{ id: number; name: string }>> {
    return this.settingsService.getEmployeeListApplicationSettings(search).pipe(
      tap(users => {
        ctx.patchState({
          usersList: users,
        });
      }),
    );
  }

  @Action(SetEmployeeApplicationSettings)
  setEmployeeApplicationSettings(
    ctx: StateContext<SettingsStateModel>,
    { params, changes }: SetEmployeeApplicationSettings,
  ): Observable<EmployeeApplicationSettings> {
    const state = ctx.getState();
    if (changes.deleteUsers && !changes.setSettings) {
      return this.settingsService.deleteEmployeeApplicationSettings(state.markForDelete).pipe(
        tap((value: EmployeeApplicationSettings) => {
          ctx.patchState({
            emplAppSettings: value,
            markForDelete: [],
          });
        }),
      );
    } else if (!changes.deleteUsers && changes.setSettings) {
      return this.settingsService.setEmployeeApplicationSettings(params).pipe(
        tap((value: EmployeeApplicationSettings) => {
          ctx.patchState({
            emplAppSettings: value,
            markForDelete: [],
          });
        }),
      );
    } else {
      return this.settingsService.setEmployeeApplicationSettings(params).pipe(
        switchMap(value => {
          let userDelete = state.markForDelete;
          params.users?.forEach(user => {
            userDelete = userDelete.filter(i => i !== user);
          });
          if (userDelete.length) {
            return this.settingsService.deleteEmployeeApplicationSettings(userDelete);
          } else {
            return of(value);
          }
        }),
        tap((value: EmployeeApplicationSettings) => {
          ctx.patchState({
            emplAppSettings: value,
            markForDelete: [],
          });
        }),
      );
    }
  }

  @Action(MarkForDeleteEmployeeApplicationSettings)
  deleteEmployeeApplicationSettings(ctx: StateContext<SettingsStateModel>, { idEmpl, unMark }: MarkForDeleteEmployeeApplicationSettings): void {
    const state = ctx.getState();
    if (!unMark) {
      state.markForDelete.push(idEmpl);
    } else {
      ctx.patchState({
        markForDelete: state.markForDelete.filter(user => user !== idEmpl),
      });
    }
  }

  @Action(ResetEmployeeApplicationSettings)
  resetEmployeeApplicationSettings(ctx: StateContext<SettingsStateModel>) {
    ctx.patchState({
      emplAppSettings: null,
      markForDelete: [],
    });
  }

  @Action(GetEmployeeNotificationSettings)
  getEmployeeNotificationSettings(ctx: StateContext<SettingsStateModel>): Observable<EmployeeNotificationSettings> {
    return this.settingsService.getEmployeeNotificationSettings().pipe(
      tap((res: EmployeeNotificationSettings) => {
        ctx.patchState({
          notificationsSettings: res,
          markForDelete: [],
        });
      }),
    );
  }

  @Action(SetEmployeeNotificationSettings)
  setEmployeeNotificationSettings(ctx: StateContext<SettingsStateModel>, { params, changes }): Observable<EmployeeNotificationSettings> {
    const state = ctx.getState();
    if (changes.deleteUsers && !changes.setSettings) {
      return this.settingsService.deleteEmployeeNotificationSettings(state.markForDelete).pipe(
        tap((value: EmployeeNotificationSettings) => {
          ctx.patchState({
            notificationsSettings: value,
            markForDelete: [],
          });
        }),
      );
    } else if (!changes.deleteUsers && changes.setSettings) {
      return this.settingsService.setEmployeeNotificationSettings(params).pipe(
        tap((value: EmployeeNotificationSettings) => {
          ctx.patchState({
            notificationsSettings: value,
            markForDelete: [],
          });
        }),
      );
    } else {
      return this.settingsService.setEmployeeNotificationSettings(params).pipe(
        switchMap(settings => {
          if (state.markForDelete.length) {
            return this.settingsService.deleteEmployeeNotificationSettings(state.markForDelete).pipe(
              tap((value: EmployeeNotificationSettings) => {
                ctx.patchState({
                  notificationsSettings: value,
                  markForDelete: [],
                });
              }),
            );
          } else {
            return of(settings);
          }
        }),
      );
    }
  }

  @Action(ResetEmployeeNotificationSettings)
  resetEmployeeNotificationSettings(ctx: StateContext<SettingsStateModel>) {
    ctx.patchState({
      notificationsSettings: null,
      markForDelete: [],
    });
  }

  @Action(GetTrainingPlanAssignSetting)
  getTrainingPlanAssignSetting(ctx: StateContext<SettingsStateModel>): Observable<TrainingPlanAssignSettingModel> {
    return this.settingsService.getTrainingPlanAssignSetting().pipe(
      tap((res: TrainingPlanAssignSettingModel) => {
        ctx.patchState({
          trainingPlanAssignSettingData: res,
          errorsTrainingPlan: null,
        });
      }),
    );
  }

  @Action(UpdateTrainingPlanAssignSetting)
  updateTrainingPlanAssignSetting(ctx: StateContext<SettingsStateModel>, { payload }: UpdateTrainingPlanAssignSetting) {
    return this.settingsService.updateTrainingPlanAssignSetting(payload).pipe(
      tap(() => {
        ctx.patchState({
          errorsTrainingPlan: null,
        });
      }),
      catchError((err: HttpErrorResponse) => {
        ctx.patchState({
          errorsTrainingPlan: err.error,
        });
        return throwError(err);
      }),
    );
  }

  @Action(GetTrainingPlansForAssign)
  getTrainingPlansForAssign(ctx: StateContext<SettingsStateModel>, { search }: GetTrainingPlansForAssign) {
    return this.settingsService.getTrainingPlansForAssign(search).pipe(
      tap((res: IdTitleObject[]) => {
        ctx.patchState({
          trainingPlansForAssign: res,
        });
      }),
    );
  }

  @Action(ClearTrainingPlans)
  clearTrainingPlans(ctx: StateContext<SettingsStateModel>) {
    ctx.patchState({
      trainingPlanAssignSettingData: null,
      trainingPlansForAssign: null,
      errorsTrainingPlan: null,
    });
  }

  @Action(GetTypeSignatureStatus)
  getTypeSignatureStatus(ctx: StateContext<SettingsStateModel>): Observable<{ isEnable: boolean }> {
    return this.settingsService.getTypeSignatureStatus().pipe(
      tap((res: { isEnable: boolean }) => {
        ctx.patchState({
          isEnableTypeSignature: res.isEnable,
        });
      }),
    );
  }

  @Action(ChangeTypeSignatureStatus)
  changeTypeSignatureStatus(ctx: StateContext<SettingsStateModel>): Observable<{ isEnable: boolean }> {
    return this.settingsService.changeTypeSignatureStatus(ctx.getState().isEnableTypeSignature).pipe(
      tap((res: { isEnable: boolean }) => {
        ctx.patchState({
          isEnableTypeSignature: res.isEnable,
        });
      }),
    );
  }

  @Action(SetTypeSignatureStatus)
  setTypeSignatureStatus(ctx: StateContext<SettingsStateModel>, { isEnable }: SetTypeSignatureStatus) {
    ctx.patchState({
      isEnableTypeSignature: isEnable,
    });
  }
}

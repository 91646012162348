import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  EmployeeApplicationSettings,
  EmployeeApplicationSettingsParams,
  EmployeeFaceToFaceReviewersModel,
  EmployeeNotificationSettings,
  EmployeeNotificationSettingsParams,
  TrainingPlanAssignSettingModel,
} from '../../shared/models/settings.models';
import { IdTitleObject } from '../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private urlV2: string;

  constructor(
    private http: HttpClient,
    @Inject('API_URL') private apiUrl: string,
  ) {
    this.urlV2 = `${this.apiUrl}/v2/settings`;
  }

  public getEmployeeListApplicationSettings(search: string): Observable<any> {
    return this.http.get<EmployeeApplicationSettings>(`${this.urlV2}/employee-assign/users`, { params: { search } });
  }

  public getEmployeeFaceToFaceReviewersListSetting(): Observable<EmployeeFaceToFaceReviewersModel[]> {
    return this.http.get<EmployeeFaceToFaceReviewersModel[]>(`${this.urlV2}/employee-face-to-face-reviewers`);
  }

  public updateEmployeeFaceToFaceReviewersListSetting(users: string[]): Observable<EmployeeFaceToFaceReviewersModel[]> {
    return this.http.put<EmployeeFaceToFaceReviewersModel[]>(`${this.urlV2}/employee-face-to-face-reviewers`, {
      users,
    });
  }

  public getEmployeeApplicationSettings(): Observable<EmployeeApplicationSettings> {
    return this.http.get<EmployeeApplicationSettings>(`${this.urlV2}/employee-assign`);
  }

  public setEmployeeApplicationSettings(params: EmployeeApplicationSettingsParams): Observable<EmployeeApplicationSettings> {
    return this.http.put<EmployeeApplicationSettings>(`${this.urlV2}/employee-assign`, { ...params });
  }

  public deleteEmployeeApplicationSettings(users: string[] | number[]): Observable<EmployeeApplicationSettings> {
    return this.http.delete<EmployeeApplicationSettings>(`${this.urlV2}/employee-assign`, { params: { 'users[]': users } });
  }

  public getEmployeeNotificationSettings(): Observable<EmployeeNotificationSettings> {
    return this.http.get<EmployeeNotificationSettings>(`${this.urlV2}/notifications`);
  }

  public setEmployeeNotificationSettings(params: EmployeeNotificationSettingsParams): Observable<EmployeeNotificationSettings> {
    return this.http.put<EmployeeNotificationSettings>(`${this.urlV2}/notifications`, { ...params });
  }

  public deleteEmployeeNotificationSettings(users: string[] | number[]): Observable<EmployeeNotificationSettings> {
    return this.http.delete<EmployeeNotificationSettings>(`${this.urlV2}/notifications`, { params: { 'users[]': users } });
  }

  getTrainingPlanAssignSetting(): Observable<TrainingPlanAssignSettingModel> {
    return this.http.get<TrainingPlanAssignSettingModel>(`${this.apiUrl}/v2/settings/training-plan-assign`);
  }

  updateTrainingPlanAssignSetting(payload: TrainingPlanAssignSettingModel): Observable<TrainingPlanAssignSettingModel> {
    return this.http.put<TrainingPlanAssignSettingModel>(`${this.apiUrl}/v2/settings/training-plan-assign`, payload);
  }

  getTrainingPlansForAssign(search: string = ''): Observable<IdTitleObject[]> {
    return this.http.get<IdTitleObject[]>(`${this.apiUrl}/v2/settings/training-plan-assign/plans`, {
      params: {
        search,
      },
    });
  }

  getTypeSignatureStatus(): Observable<{ isEnable: boolean }> {
    return this.http.get<{ isEnable: boolean }>(`${this.apiUrl}/v2/settings/type-signature`);
  }

  changeTypeSignatureStatus(isEnable: boolean): Observable<{ isEnable: boolean }> {
    return this.http.put<{ isEnable: boolean }>(`${this.apiUrl}/v2/settings/type-signature`, {
      isEnable,
    });
  }
}
